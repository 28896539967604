












































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

@Component({})
export default class companyDetails extends Vue {
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    queryType: '1', // 1 出函 2 退保
    company: '',
    keyword: '',
    phone: '',
    channelId: '',
    tradingCenterCode: "",
    sendingOrganizationCode: "",
    orderOrigin: '',
    sendLetterTime: [],
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.search.tradingCenterCode = this.$route.query.tradingCenterCode;
    this.search.sendingOrganizationCode = this.$route.query.sendingOrganizationCode;
    this.search.channelId = this.$route.query.channelId;
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30132", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getType(e: any) {
    console.log('ee', e)
    this.searchTable()
  }


  config: any = {}
  getConf() {
    api.getConf().then((res) => {
      this.config = res
    })
  }

  getStar(value: string, start = 3, end = 8) {
    return value.replace(value.substring(start, end), "****")
  }

  activated() {
    this.search.tradingCenterCode = this.$route.query.tradingCenterCode;
    this.search.sendingOrganizationCode = this.$route.query.sendingOrganizationCode;
    this.search.channelId = this.$route.query.channelId;
    // this.search.tradingCenterCode = 'AOU00001F7';
    // this.search.channelId = '1513770909666668546';
    this.searchTable();
    this.getConf()
  }
}
